<template>
  <div class="w-100">
    <button
      v-if="$isEmpty(userId)"
      @click="loginWithMyInfo"
      class="singpass-button w-100"
    >
      Login with singpass
    </button>
    <b-img 
      v-else
      class="cursor-pointer w-100"
      :src="
        require('@/assets/images/singpass/myinfo/myinfo button Inline/Primary.svg')
      "
      @click="retrieveFromMyInfo"
    />
  </div>
</template>

<script>
export default {
  props: {
    userId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {

    }
  },
  
  mounted() {
    // const queries = this.$route.query

    // if (this.$isNotEmpty(queries.myinfo_login_id) && this.$isNotEmpty(queries.myinfo_login_verifier_code)) {
    //   this.initMyinfoLogin(queries)
    // }

    // if (
    //   queries.myinfo_status == 'success'
    //     && this.$isNotEmpty(queries.myinfo_message)
    // ) {
    //   this.$toastSuccess('Singpass Operation', queries.myinfo_message)
    // } else if (
    //   queries.myinfo_status == 'failed'
    //     && this.$isNotEmpty(queries.myinfo_message)
    // ) {
    //   this.$toastDanger('Singpass Operation', queries.myinfo_message)
    // }
  },

  methods: {
    initMyinfoLogin(queries) {
      this.$http
        .post(`/api/auth/myinfo/login`)
        .then(response => {
          const responseData = response.data
          if (responseData.success) {
            const userScore = responseData.score
            this.score = userScore
            if (this.score < 0.4) {
              this.loginText = 'Refresh this page or try again later'
            }
          }
        }).catch(error => {
        })

    },

    retrieveFromMyInfo() {
      // const redirectUrl = `https://myinfo.headhuntershq.com?user_id=${this.userId}`

      // const redirectUrl = `http://localhost:80/myinfo/?user_id=${this.userId}`
      // const redirectUrl = `http://localhost:3001?user_id=${this.userId}`
      const redirectUrl = `${this.$myInfoCallbackURL}?user_id=${this.userId}`

      localStorage.setItem('page_before_myinfo', this.$route.name)

      window.location = redirectUrl
      // window.open(redirectUrl, '_blank');
    },

    loginWithMyInfo() {
      const redirectUrl = `${this.$myInfoCallbackURL}?init_login=true`
      // const redirectUrl = `${this.$myInfoCallbackURL}?user_id=${this.userId}`

      localStorage.setItem('page_before_myinfo', this.$route.name)

      window.location = redirectUrl
    },
  }
}
</script>

<style lang="scss" scoped>
.singpass-button {
  color: white;
  background: #F3333D;

  padding: .5rem;
  border: none;
  border-radius: .3rem;
}
</style>
