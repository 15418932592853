import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

import Multiselect from 'vue-multiselect'
import ProfileCompletionForm from '@/components/ProfileCompletionForm.vue'
import FileUpload from '@/components/FileUpload.vue'
import ViewDocument from '@/components/ViewDocument.vue'

Vue.use(BootstrapVue)
Vue.component(FeatherIcon.name, FeatherIcon)

Vue.component('multiselect', Multiselect)
Vue.component('ProfileCompletionForm', ProfileCompletionForm)
Vue.component('FileUpload', FileUpload)
Vue.component('ViewDocument', ViewDocument)
